import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { DARK, MEDIUM } from '@constants';
import {
  Layout, OuterWrapper, ContentWrapper, RawHtml, BasicDarkHero,
} from '@components';
import { useScript } from '@hooks';
import { getKeyValue } from '@utils';

const JobpageTemplate = ({ location, data, pageContext }) => {
  const {
    site, job, settings,
  } = data;
  const { slug: path } = pageContext;
  const status = useScript(
    'https://boards.greenhouse.io/embed/job_board/js?for=bluestatecampaigns',
  );
  const [formLoaded, setFormLoaded] = useState(false);

  const { locale } = pageContext;
  const { title: siteTitle, social } = getKeyValue(site, 'siteMetadata') || {};
  const {
    title, greenhouseId, content,
  } = job || {};
  const {
    defaultMetaImage: metaImage,
  } = settings || {};
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};

  useEffect(() => {
    const Grnhse = window.Grnhse || {};
    if (status === 'ready' && Grnhse && Grnhse.Iframe && !formLoaded) {
      Grnhse.Iframe.load(greenhouseId);
      setFormLoaded(true);
    }
  }, [status, greenhouseId, formLoaded]);

  return (
    <Layout
      location={location}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${path}`}
      metaTitle={`${title} | ${siteTitle}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      locale={locale}
      navTheme={DARK}
      settings={settings}
      navAnimate
    >
      <BasicDarkHero title={title} />
      <OuterWrapper gutterSize={MEDIUM} normalMargin>
        <ContentWrapper>
          <RawHtml dangerouslySetInnerHTML={{ __html: content }} />
          <div id="grnhse_app" />
        </ContentWrapper>
      </OuterWrapper>
    </Layout>
  );
};

export default JobpageTemplate;

export const jobpageQuery = graphql`
  query JobpageBySlug($locale: String!, $jobid: String!) {
    site {
      ...SiteMetadata
    }
    job: greenhouseJobPost(job_id: { eq: $jobid }) {
      title
      job_id
      greenhouseId
      content
    }
    settings: contentfulSiteSettings(node_locale: { eq: $locale }) {
      ...SiteSettings
      ...DefaultMetaImage
    }
  }
`;
